import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import logo from './img/the-astrolabe-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/wall" element={<WallOfPosts/>} />
      </Routes>
    </Router>
  );
}

export default App;

function Home() {
  return (
    <div id="parent">
    <div class="container mt-5 mb-5">
      <div class="row logo d-flex align-items-center">
        <img src={logo} alt="The Astolabe Logo" class="mx-auto logoimg"/>
      </div>
    </div>
    <div class="container mt-5 mb-5">
      <div class="row" id="breathe-title">
        <div class="col">
          <h1 class="text-center">Blah, Blah, Blah</h1>
          <h2 class="text-center">New single and video</h2>
        </div>
      </div>
    </div>
    <div class="container mt-5 mb-5">
      <div class="row" id="breathe-video">
        <div class="col">
          <div class="embed-responsive embed-responsive-16by9">
          <iframe title="The Astrolabe /// BLAH BLAH BLAH" class="embed-responsive-item" src="https://www.youtube.com/embed/KGL4kyUp9mQ?rel=0" width="520" height="960" allow="fullscreen;" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5 mb-5 text-center">
      <div class="row" id="breathe-video">
        <div class="col">
          <a href="https://theastrolabe.ffm.to/blah-blah-blah" target="_blank" rel="noreferrer" class="btn btn-lg px-3">Listen</a>
        </div>
      </div>
    </div>
    <div class="container mt-5 mb-5 text-center">
      <div class="row" id="loud-social">
        <div class="col">
          <div class="social-bar-container-wrapper">
            <div class="social-bar-container">
              <ul class="menu">
                <li>
                  <a href="https://open.spotify.com/album/5IPiaSttw0ufcMetN5xmgt" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faSpotify} />
                  </a>
                </li>
                <li>
                  <a href="https://music.apple.com/gr/album/blah-blah-blah-single/1742791234" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faApple} />
                  </a>
                </li>
                <li>
                  <a href="http://www.instagram.com/theastrolabe_" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li>
                  <a href="http://www.facebook.com/theastrolabe" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/@theastrolabe" target="_blank" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook" class="svg-inline--fa fa-facebook " role="img"><path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    );
}

function WallOfPosts() {
  const [posts, setPosts] = useState([]);
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    fetchMessages();

    const interval = setInterval(() => {
      fetchMessages();
    }, 5000); // Refresh every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get('https://theastrolabe-official.com/api');
      const reversedPosts = response.data.reverse(); // Reverse the order of the array
      const limitedPosts = reversedPosts.slice(0, 100); // Get only the first 100 posts
      setPosts(limitedPosts);
    } catch (error) {
      console.error('Error retrieving messages', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'name') {
      setName(value);
    } else if (name === 'message') {
      setMessage(value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name.trim() !== '' && message.trim() !== '') {
      try {
        await axios.post('https://theastrolabe-official.com/api', { name, message });
        await fetchMessages();
        setName('');
        setMessage('');
      } catch (error) {
        console.error('Error creating message', error);
      }
    }
  };

  return (
  <div id="parent" class="wall-of-posts">
	<div class="container mt-4 mb-2 text-center">
	  <div class="row logo">
	    <a href="https://theastrolabe-official.com"><img src={logo} alt="The Astolabe Logo" class="mx-auto"/></a>
	  </div>
	</div>
	<div class="container mt-2 mb-2">
	  <div class="row" id="breathe-title">
	    <div class="col">
	      <h4 class="text-center">BLAH BLAH BLAH (Official video)</h4>
	    </div>
	  </div>
	</div>
	<div class="container text-center mt-2 mb-2">
	  <div class="row" id="breathe-video">
	    <div class="col">
	      <div class="embed-responsive embed-responsive-16by9">
	        <iframe title="The Astrolabe /// BLAH BLAH BLAH" class="embed-responsive-item" src="https://www.youtube.com/embed/OPXKOOyRLHs?rel=0" height="200" allow="fullscreen;" allowfullscreen></iframe>
	      </div>
	    </div>
	  </div>
	</div>

<div class="container mt-4 mb-2">
  <div class="row message-board-form">
    <div class="col text-center">
  <form id="message-board-form" class="form-inline" onSubmit={handleSubmit}>
      <div class="form-group">
        <input
          id="name"
          type="text"
          name="name"
          value={name}
          onChange={handleInputChange}
          placeholder="Your Name"
          maxlength="64"
          class="form-control w-50 mx-auto mb-2 mr-sm-2"
        />
      </div>
      <div class="form-group">
          <input
            id="message"
            type="text"
            name="message"
            value={message}
            onChange={handleInputChange}
            placeholder="Your love message (max 96 chars)..."
            maxlength="96"
            class="form-control w-50 mx-auto mb-2 mr-sm-2"
          />
        </div>
	
        <button type="submit" class="btn btn-danger mb-2">Add message</button>
        </form>

  </div>
  </div>
  </div>
  <div class="container mt-2 mb-4">

    <div class="row pt-5" id="message-board">
        <div class="col speechbubble-column">

    {posts.map((post, index) => (
      <div class="speechbubble" key={index}>
        <span class="username">{post.name}</span>
        <p>
          {post.message}
        </p>
      </div>
    ))}

</div>
</div>
</div>

</div>
  );
}

export { Home, WallOfPosts };
